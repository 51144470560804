

























import { IOption } from "@interface/common.interface";
import { ResponseListProduct, ResponseProduct } from "@interface/product.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { productService } from "@service/product.service";
import Vue from "vue";
import MNotificationVue from "@/mixins/MNotification.vue";
export default Vue.extend({
  name: "CSelectBox",
  mixins: [
    MNotificationVue,
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      dtOpt: [] as IOption<ResponseProduct>[],
      loading: false,
      queryParams: {
        page: 0,
        limit: 10,
        search: "code~*#BOX"
      },
    };
  },
  created() {
    this.getList(this.queryParams);
  },
  methods: {
    getListMasterProducts(params: RequestQueryParamsModel): Promise<ResponseListProduct> {
      return productService.listProduct(params);
    },
    onSelect(value: string, meta: ResponseProduct): void {
      this.$emit("input", value);
      this.$emit("on-select", { value, meta });
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListMasterProducts(params);
        const { dtOpt } = this;
        const opts = res.data.map(x => ({ key: x.code, value: x.id, meta: x }));
        this.dtOpt = [...dtOpt, ...opts];
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
  }
});
